import { useDispatch, useSelector } from "react-redux";
import { productGet } from "../../redux/slice/productSlice";
import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import { BestCollection, ProductSkeleton } from "./BestSeller";
import { categoryWiseProduct } from "../../redux/slice/categorySlice";

const CategoryWiseProduct = () => {
  const dispatch = useDispatch();
  const { loginUser } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const { categoryProduct, isSkeleton2 } = useSelector(
    (state) => state.category
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(categoryWiseProduct(loginUser._id || parsedSessionUserId?._id));
  }, []);
  useEffect(() => {
    dispatch(categoryWiseProduct(loginUser._id || parsedSessionUserId?._id));
  }, [loginUser]);

  useEffect(() => {
    setData(categoryProduct);
  }, [categoryProduct]);

  return (
    <section id="CategoryWiseProduct">
      {data?.map(
        (res) =>
          res.categoryProductCount > 0 && (
            <div className="container">
              <Title name={res.categoryName} />
              <div className="row">
                {!isSkeleton2 ? (
                  res?.categoryProduct?.length > 0 ? (
                    <>
                      {res?.categoryProduct?.map((pair, index) => (
                        <div
                          className="col-xxl-2 col-md-3 col-sm-4 col-6"
                          key={index}
                        >
                          <BestCollection
                            className={`m15-bottom`}
                            data={pair}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    [...Array(5)].map((_, index) => (
                      <ProductSkeleton
                        key={index}
                        className={`col-xxl-2 col-md-3 col-sm-4 col-6`}
                      />
                    ))
                  )
                ) : (
                  [...Array(6)].map((_, index) => (
                    <ProductSkeleton
                      key={index}
                      className={`col-xxl-2 col-md-3 col-sm-4 col-6`}
                    />
                  ))
                )}
              </div>
            </div>
          )
      )}
    </section>
  );
};

export default CategoryWiseProduct;
