import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productGet } from '../../redux/slice/productSlice';
import { BestCollection, ProductSkeleton } from './BestSeller';
import Title from '../extra/Title';
import Aos from 'aos';

const SuggetionProduct = () => {

  const dispatch = useDispatch();

  const { loginUser } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const { product, isSkeleton } = useSelector((state) => state.product);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(productGet(loginUser._id || parsedSessionUserId?._id));
  }, []);
  useEffect(() => {
    dispatch(productGet(loginUser._id || parsedSessionUserId?._id));
  }, [loginUser]);

  useEffect(() => {
    setData(product);
  }, [product]);


  


  return (
    <section id="BestSeller" className="carousel-mini">
      <div className="container">
        <Title name={`Our Best Seller`} />
        <div className="row">
          {!isSkeleton ? (
            data?.length > 0 ? (
              <>
                {data.map((pair, index) => (
                  <div
                    className="col-xxl-2 col-md-3 col-sm-4 col-6"
                    key={index}
                  >
                    <BestCollection className={`m15-bottom`} data={pair} />
                  </div>
                ))}
              </>
            ) : (
              [...Array(5)].map((_, index) => (
                <ProductSkeleton
                  key={index}
                  className={`col-xxl-2 col-md-3 col-sm-4 col-6`}
                />
              ))
            )
          ) : (
            [...Array(6)].map((_, index) => (
              <ProductSkeleton
                key={index}
                className={`col-xxl-2 col-md-3 col-sm-4 col-6`}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
}

export default SuggetionProduct;
