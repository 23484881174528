import React, { useEffect, useState } from "react";
import Header from "./globle/Header";
import Footer from "./globle/Footer";
import Saree from "../assets/images/saree.png";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveCart, removeCart, showCart } from "../redux/slice/cartSlice";
import CartImage from "../assets/images/cart.png";
import { useNavigate } from "react-router-dom";
import { baseURL } from "./utils/config";
import Service from "./components/Service";
const Cart = () => {
  const dispatch = useDispatch();
  const { cart, isSkeleton } = useSelector((state) => state.cart);
  const { loginUser, isAuth } = useSelector((state) => state.userAuth);

  useEffect(() => {
    if (loginUser._id) {
      dispatch(showCart(loginUser._id));
    }
  }, [loginUser._id]);

  const calculateTotal = (property) =>
    !isSkeleton && cart?.allProduct?.length > 0
      ? cart.allProduct.reduce((acc, res) => acc + res[property], 0)
      : "0";
  const finalSavePrice = calculateTotal("finalSavePrice");
  const productCount = calculateTotal("productCount");
  const finalPrice = calculateTotal("finalPrice");
  const finalOldPrice = calculateTotal("finalOldPrice");
  const navigate = useNavigate();

  const handleCheckout = () => {
    const oldcart = {
      ...cart,
      finalSavePrice,
      productCount,
      finalPrice,
      finalOldPrice,
    };
    localStorage.setItem("cart", JSON.stringify(cart));
    navigate("/checkout", { state: oldcart });
  };

  return (
    <>
      <Header />

      <div className="mainCart m30-top">
        <div className="container">
          <div className="cart" style={{ minHeight: "580px" }}>
            <div className="row">
              <div className="col-lg-9 col-md-7 col-sm-8 col-12">
                {!isSkeleton ? (
                  cart?.allProduct?.length > 0 ? (
                    cart?.allProduct?.map((res, index) => {
                      return <CartBox key={index} data={res} />;
                    })
                  ) : (
                    <div className="d-flex justify-content-center">
                      <img src={CartImage} alt="" className="noData" />
                    </div>
                  )
                ) : (
                  [...Array(2)]?.map(() => {
                    return <CartBoxSkeleton />;
                  })
                )}
              </div>
              <div className="col-lg-3 col-md-5 col-sm-4 col-12">
                <div className="cartTotal p10">
                  {!isSkeleton ? (
                    cart ? (
                      <div className="countTotalOrder m10-top">
                        <ul className="mainCountOrder p10 border-bottom-gray-1">
                          <li>
                            <span>
                              Sub Total{" "}
                              <span className="text-gray fs-lg-16 fs-md-12 fs-10">
                                ({productCount || cart?.productCount} items)
                              </span>{" "}
                            </span>
                            <span>₹{finalOldPrice || cart?.finalOldPrice}</span>
                          </li>
                          <li>
                            <span>Discount</span>
                            <span className="text-success">
                              - ₹{finalSavePrice || cart?.finalSavePrice}
                            </span>
                          </li>
                          <li>
                            <span>Shipping</span>
                            <span className="text-success">Free</span>
                          </li>
                          <li>
                            <span>Discount</span>
                            <span className="text-success"></span>
                          </li>
                        </ul>
                        <ul className="mainCountOrder productTotal p10-x">
                          <li>
                            <span>Total</span>
                            <span>₹{finalPrice || cart?.finalPrice}</span>
                          </li>
                          <li
                            className={`primeButton ${
                              finalPrice == 0 && "d-none"
                            }`}
                          >
                            <button
                              type="button"
                              className="m10-right"
                              onClick={() => handleCheckout()}
                            >
                              Checkout
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      "No Data"
                    )
                  ) : (
                    <div className="countTotalOrder m10-top">
                      <ul className="mainCountOrder p10 border-bottom-gray-1">
                        <li className="skeleton height-25 m10-top"></li>
                        <li className="skeleton height-25 m10-top"></li>
                        <li className="skeleton height-25 m10-top"></li>
                        <li className="skeleton height-25 m10-top"></li>
                      </ul>
                      <ul className="mainCountOrder productTotal p10-x">
                        <li className="skeleton height-25 m10-top"></li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Service />
      <Footer />
    </>
  );
};

export default Cart;

export const CartBox = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const hanldeaddRemove = (cartId, action, price, oldPrice) => {
    dispatch(addRemoveCart({ cartId, action, price, oldPrice }));
  };

  const handleDeleteCart = (cartId) => {
    dispatch(removeCart(cartId));
  };

  // const [count, setCount] = useState(data?.productCount)

  return (
    <div className="cartBox d-flex align-items-center m10-bottom">
      <div className="cartImage m20-right text-center">
        <div className="cartImage  width-lg-100 width-80 height-lg-150 height-md-130 height-115">
          <img src={baseURL + data?.productImage[0]} alt="cart" />
        </div>
        <div className="productSizes mt-2 fs-xl-16 fs-14">
          <span className="showSize bg-second text-light m0 p5 fs-14">{data?.productSize}</span>
        </div>
      </div>
      <div className="cartDetails p10">
        <div className="prductTitle fw-600 fs-lg-20 fs-md-16 fs-12 m0-bottom">
          {data?.title}
        </div>
        {/* <span style={{ backgroundColor: "red" }} className='width-md-20 width-15 height-5 d-block m5-bottom'></span> */}
        <div className="productPrice m4-top fs-xl-16 fs-12">
          <span className="fw-600 me-2 ">₹{data?.price}</span>
          <span className="fw-600 text-gray">
            <del>₹{data?.oldPrice}</del>
          </span>
        </div>
        <div className="fs-lg-20 fs-xl-16 fs-12  m10-bottom m5-top text-success">
          #{data?.productCode}
        </div>
        <div className="productQUT m10-bottom m15-md-bottom fs-xl-16 fs-14">
          <div className="counter">
            {data?.productCount <= 1 ? (
              <span
                className="fw-600 fs-xl-14 fs-12 cntBtn cursor-pointer"
                onClick={() => handleDeleteCart(data?._id)}
              >
                <i class="ri-delete-bin-6-line"></i>
              </span>
            ) : (
              <span
                className="fw-600 fs-xl-16 fs-14 cntBtn cursor-pointer"
                onClick={() =>
                  hanldeaddRemove(data?._id, false, data?.price, data?.oldPrice)
                }
              >
                -
              </span>
            )}

            <span className="fw-600 fs-xl-16 fs-14 cnt">
              {data?.productCount}
            </span>
            <span
              className="fw-600 fs-xl-16 fs-14 cntBtn cursor-pointer"
              onClick={() =>
                hanldeaddRemove(data?._id, true, data?.price, data?.oldPrice)
              }
            >
              {" "}
              +
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center flex-wrap m10-bottom">
          <div className="fw-600 fs-xl-16 fs-12">₹{data?.finalPrice} </div>
          <div className="fw-600 m15-left fs-xl-16 fs-12 text-gray">
            {" "}
            <del>₹{data?.finalOldPrice}</del>{" "}
          </div>
          <div className="fw-600 m15-left fs-xl-16 fs-12 text-success text-nowrap">
            save up to {data?.finalOldPrice - data?.finalPrice}₹ off{" "}
          </div>
        </div>
        <div className="cartInfo fs-lg-16 fs-md-14 fs-12 text-second">
          {/* <span>Save for later</span> */}
          <span onClick={() => handleDeleteCart(data?._id)}>Delete</span>
          {/* <span>Share</span> */}
        </div>
      </div>
    </div>
  );
};
export const CartBoxSkeleton = (props) => {
  return (
    <div className="cartBox d-flex align-items-center m10-bottom">
      <div className="cartImage m20-right width-lg-100 width-80 height-lg-150 height-md-130 height-115 skeleton"></div>
      <div className="cartDetails p10 w-100">
        <div className="prductTitle fw-600 fs-lg-20 fs-md-16 fs-12 m0-bottom skeleton height-30 w-60"></div>
        <div className="productPrice m4-top fs-xl-16 fs-12 skeleton height-24 w-25"></div>
        <div className="fs-lg-20 fs-xl-16 fs-12  m10-bottom m5-top text-success skeleton height-24 w-25"></div>
        <div className="productQUT m10-bottom m15-md-bottom fs-xl-16 fs-14 skeleton height-30 w-25"></div>
        <div className="d-flex align-items-center flex-wrap m10-bottom skeleton height-25 w-50"></div>
        <div className="cartInfo fs-lg-16 fs-md-14 fs-12 text-second skeleton height-20 w-30"></div>
      </div>
    </div>
  );
};
