import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  category: [],
  isSkeleton: false,
  categoryProduct: [],
  isSkeleton2: false,
};
export const categoryGet = createAsyncThunk("category/show", async () => {
  return apiInstance.get("category/show");
});
export const categoryWiseProduct = createAsyncThunk(
  "category/categoryWiseProduct",
  async (payload) => {
    return apiInstance.get(
      `category/categoryWiseProduct?userId=${payload}`
    );
  }
);
// export const addToWishlist = createAsyncThunk("wishlist/create", async (payload) => {
//   return apiInstance.post(`wishlist/create?userId=${payload.userId}&productId=${payload.productId}`);
// });

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // categoryGet
    builder.addCase(categoryGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(categoryGet.fulfilled, (state, action) => {
      state.category = action.payload.category;
      state.isSkeleton = false;
    });
    builder.addCase(categoryGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(categoryWiseProduct.pending, (state, action) => {
      state.isSkeleton2 = true;
    });
    builder.addCase(categoryWiseProduct.fulfilled, (state, action) => {
      state.categoryProduct = action.payload.category;
      state.isSkeleton2 = false;
    });
    builder.addCase(categoryWiseProduct.rejected, (state, action) => {
      state.isSkeleton2 = false;
    });
  },
});
export default categorySlice.reducer;
