import React, { useEffect, useState } from "react";
import Header from "./globle/Header";
import Footer from "./globle/Footer";
import Saree from "../assets/images/saree.png";
import Saree1 from "../assets/images/saree1.png";
import Saree2 from "../assets/images/saree2.png";
import Saree3 from "../assets/images/saree3.png";
import Button from "./extra/Button";
import { useDispatch, useSelector } from "react-redux";
import { removeWishlist, showWishlist } from "../redux/slice/wishlistSlice";
import Cart from "../assets/images/cart.png";
import { ProductSkeleton } from "./components/BestSeller";
import { useNavigate } from "react-router-dom";
import { addCart } from "../redux/slice/cartSlice";
import { baseURL } from "./utils/config";
import Service from "./components/Service";
const Wishlist = () => {
  const { loginUser } = useSelector((state) => state.userAuth);
  const { wishlist, isSkeleton } = useSelector((state) => state.wishlist);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showWishlist(loginUser._id));
  }, [loginUser._id]);

  return (
    <>
      <Header />
      <div id="wishlist">
        <div className="mainWishlist">
          <div className="container">
            <div className="row">
              {!isSkeleton ? (
                wishlist?.length > 0 ? (
                  <>
                    {wishlist?.map((res) => {
                      return (
                        <div className="col-xxxl-2 col-lg-3 col-md-4 col-xsm-6 col-12">
                          <ShowMyWishlist data={res} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <img src={Cart} alt="" className="noData" />
                )
              ) : (
                [...Array(4)]?.map(() => {
                  return (
                    <div className="col-xxxl-2 col-lg-3 col-md-4 col-xsm-6 col-12">
                      <WishlistSkeleton />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <Service />
      <Footer />
    </>
  );
};

export default Wishlist;

// ======================== ShowMyWishlist =======================
export const ShowMyWishlist = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleShowImage = (id, productCode) => {
    navigate(`/profile/${productCode}`);
    localStorage.setItem("wishId", id);
    const myDiv = document.getElementById("SareeProfileMain");
    if (myDiv) {
      myDiv.scrollTo(0, 0);
    }
  };
  const handleRemove = (id) => {
    dispatch(removeWishlist(id));
  };
  const { loginUser } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const handleAddToCart = (productId, cnt) => {
    dispatch(
      addCart({
        productId: productId,
        userId: loginUser?._id || parsedSessionUserId?._id,
        productCount: cnt || 1,
        productSize: data?.productSize,
      })
    );
  };
  const [count, setCount] = useState(1);

  const handleCheckout = (productDetails, productCount) => {
    const userId = loginUser?._id;
    const {
      discount,
      outOfStock,
      oldPrice,
      price,
      productCode,
      productImage,
      shippingCharge,
      title,
      productId,
    } = productDetails;
    const finalCount = productCount * 1;
    const cart = {
      allProduct: [
        {
          _id: null,
          discount,
          outOfStock,
          oldPrice,
          price,
          productCode,
          productId,
          productCount: finalCount,
          productImage,
          shippingCharge,
          title,
          userId,
          savePrice: oldPrice - price,
          finalOldPrice: oldPrice * finalCount,
          finalPrice: price * finalCount,
          finalSavePrice: (oldPrice - price) * finalCount,
        },
      ],
      oldPrice,
      price,
      finalOldPrice: oldPrice * finalCount,
      finalPrice: price * finalCount,
      finalSavePrice: (oldPrice - price) * finalCount,
      productCount: finalCount,
      totalProduct: 1,
    };
    localStorage.setItem("cart", JSON.stringify(cart));
    navigate("/checkout", { state: cart });
  };

  return (
    <div className="wishlistCard h-100">
      <div className="removeBtn" onClick={() => handleRemove(data?._id)}>
        <i class="ri-close-line"></i>
      </div>
      <div className="row h-100 justify-content-between flex-column align-items-center">
        <div
          className="cartImage col-12 mb-3"
          onClick={() => handleShowImage(data?.productId, data?.productCode)}
        >
          <img
            src={baseURL + data?.productImage[0]}
            alt="cart"
            className="w-100 height-xxxl-300 height-xxl-350 height-xl-400 height-lg-300 height-md-200 rounded-2"
          />
        </div>
        <div className="cartDetails col-12 m0">
          <h5 className="text-overflow-2 fs-lg-16 fs-14 m2-bottom">
            {data?.title}
          </h5>
          <div className="productPrice m10-y fs-xl-16 fs-14">
            <span className="fw-600 me-2 ">₹{data?.price}</span>
            <span className="fw-600 text-gray">
              <del>₹{data?.oldPrice}</del>
            </span>
          </div>
          <div className="mainWishList d-flex w-100 justify-content-between align-items-center">
            <div className="productSizes fs-xl-16 fs-14">
              <span className="showSize m0 p5 fs-14">{data?.productSize}</span>
            </div>
            <div className="productQUT d-flex">
              <span
                className="fw-600 fs-xl-16 fs-14 cntBtn"
                onClick={() => setCount(count <= 1 ? 1 : count - 1)}
              >
                -
              </span>
              <span className="fw-600 fs-xl-16 fs-14 cnt">{count}</span>
              <span
                className="fw-600 fs-xl-16 fs-14 cntBtn"
                onClick={() => setCount(count + 1)}
              >
                {" "}
                +
              </span>
            </div>
          </div>
        </div>
        <div className="col-12">
          {data?.outOfStock ? (
            <div className="themeBtn fs-xl-16 fs-14">
              <button
                type="button"
                className="m10-right border-danger-1 bg-transparent text-danger fw-bold"
              >
                Out of Stock
              </button>
            </div>
          ) : (
            <div className="themeBtn fs-xl-16 fs-14">
              {/* <button
                type="button"
                className="m10-right"
                onClick={() => handleCheckout(data, count)}
              >
                Buy Now
              </button> */}
              <button
                type="button"
                className="m10-top"
                onClick={() => handleAddToCart(data?.productId, count)}
              >
                {" "}
                Add To Cart
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const WishlistSkeleton = (props) => {
  return (
    <>
      <div className="wishlistCard">
        <div className="removeBtn">
          <i class="ri-close-line"></i>
        </div>
        <div className="row align-items-center">
          <div className="cartImage col-12 mb-3">
            <div className="w-100 height-xxxl-300 height-xxl-350 height-xl-400 height-lg-300 height-md-200 rounded-2 skeleton"></div>
          </div>
          <div className="cartDetails col-12 m0">
            <h5 className="text-overflow-2 fs-lg-16 fs-14 m2-bottom height-15 skeleton"></h5>
            <div className="productPrice m10-y fs-xl-16 fs-14 height-15 width-100 skeleton"></div>
            <div className="mainWishList d-flex w-100 justify-content-between align-items-center">
              <div className="productSizes fs-xl-16 fs-14 height-20 width-100 skeleton"></div>
              <div className="productQUT d-flex height-20 width-100 skeleton"></div>
            </div>
          </div>
          <div className="col-12">
            <div className="themeBtn fs-xl-16 fs-14">
              <button
                type="button"
                className="m10-top skeleton height-30"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
