import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../pages/extra/toast";

const initialState = {
  cart: [],
  totalProduct: 0,
  isSkeleton: false,
  cartLoader: false,
};
export const showCart = createAsyncThunk("cart/show", async (payload) => {
  return apiInstance.get(`cart/show?userId=${payload}`);
});
export const addCart = createAsyncThunk("cart/add", async (payload) => {
  return apiInstance.post(`cart/add`, payload);
});
export const addRemoveCart = createAsyncThunk("cart/addRemove", async (payload) => {
  return apiInstance.patch(`cart/addRemove`, payload);
});
export const removeCart = createAsyncThunk("cart/delete", async (payload) => {
  return apiInstance.delete(`cart/delete?cartId=${payload}`);
});


const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // showCart
    builder.addCase(showCart.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(showCart.fulfilled, (state, action) => {
      state.cart = action.payload.cart;
      state.totalProduct = action.payload.cart.totalProduct;
      state.isSkeleton = false;

    });
    builder.addCase(showCart.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // addCart
    builder.addCase(addCart.pending, (state, action) => {
      state.cartLoader = true;
    });
    builder.addCase(addCart.fulfilled, (state, action) => {
      if (action.payload.status) {

        const inCart = state.cart?.allProduct?.some(obj => obj.productId == action.payload.cart.productId &&
          obj.productSize === action.payload.cart.productSize);

        if (inCart) {
          const cartIdx = state.cart.allProduct.findIndex((product) => product._id === action.payload.cart._id);
          if (cartIdx !== -1) {
            state.cart.allProduct[cartIdx] = { ...state.cart.allProduct[cartIdx], ...action.payload.cart };
          }
        } else {
          state.cart?.allProduct.unshift(action.payload.cart);
          state.totalProduct += 1
        }
      }
      setToast("success", action.payload.message)
      state.cartLoader = false;
    });
    builder.addCase(addCart.rejected, (state, action) => {
      state.cartLoader = false;
    });


    // addRemoveCart
    builder.addCase(addRemoveCart.pending, (state, action) => {
      // state.isSkeleton = true;
    });
    builder.addCase(addRemoveCart.fulfilled, (state, action) => {
      const cartIdx = state.cart.allProduct.findIndex((product) => product._id === action.payload.cart._id);
      if (cartIdx !== -1) {
        state.cart.allProduct[cartIdx] = { ...state.cart.allProduct[cartIdx], ...action.payload.cart };
      }
      // state.isSkeleton = false;
    });
    builder.addCase(addRemoveCart.rejected, (state, action) => {
      // state.isSkeleton = false;
    });


    // CartDelete
    builder.addCase(removeCart.pending, (state, action) => {
      // state.isSkeleton = true;
    });
    builder.addCase(removeCart.fulfilled, (state, action) => {
      state.cart.allProduct = state.cart.allProduct.filter((cart) => cart._id !== action.meta.arg);
      state.totalProduct -= 1
      // state.isSkeleton = false;
    });
    builder.addCase(removeCart.rejected, (state, action) => {
      // state.isSkeleton = false;
    });

  },
});


export default cartSlice.reducer;

