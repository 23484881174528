import React, { useEffect } from "react";
import Slider from "./components/Slider";
import Category from "./components/Category";
import BestSeller from "./components/BestSeller";
import Banner, { Notice } from "./extra/Banner";
import Budget from "./components/Budget";
import Policy from "./components/Policy";
import Service from "./components/Service";
import Footer from "./globle/Footer";
import Header from "./globle/Header";
import { settingGet } from "../redux/slice/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "./utils/config";
import CategoryWiseProduct from "./components/CategoryWiseProduct";

const Home = () => {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(settingGet());
  }, []);

  return (
    <div>
      <Header />
      <Slider />
      <Category />
      <BestSeller />
      <CategoryWiseProduct />
      <Banner img={baseURL + setting?.homeBanner} />
      <Budget />
      <Notice text={setting?.webText} />
      <Policy />
      {/* <News /> */}
      <Service />
      <Footer />
    </div>
  );
};

export default Home;
