import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import SareeProfile from './pages/SareeProfile';
import Collection from './pages/collections/Collection';
import Wishlist from './pages/Wishlist';
import Checkout from './pages/Checkout';
import Cart from './pages/Cart';
import Order from './pages/Order';
import About from './pages/About';
import Contact from './pages/Contact';
import UserProfile from './pages/profile/UserProfile';
import RatingDialogue from './pages/RatingDialogue';
import "aos/dist/aos.css";


const Admin = () => {
  const { pathname } = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener('beforeunload', saveScrollPosition);

    if (scrollPosition) {
      window.scrollTo(0, scrollPosition);
    }

    // Cleanup the event listener
    return () => {
      window.removeEventListener('beforeunload', saveScrollPosition);
    };
  }, [pathname, scrollPosition]);

  const saveScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };

  return (
    <div className='webUserAdmin'>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shop/*" element={<Collection />} />
        <Route path="/profile/:prodctCode/*" element={<SareeProfile />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/order" element={<Order />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/userProfile" element={<UserProfile />} />
      </Routes>
      {/* <RatingDialogue /> */}

    </div>
  );
}

export default Admin;
{/* <Login /> */ }
{/* <Signup /> */ }
{/* <Home /> */ }
{/* <SarreType /> */ }
{/* <SareeProfile /> */ }
{/* <Checkout /> */ }


{/* <Route path="/login" element={<Login />} /> */ }
{/* <Route path="/signup" element={<Signup />} /> */ }
{/* <Route path="/saree" element={<SarreType />} />
        <Route path="/profile" element={<SareeProfile />} />
        <Route path="/checkout" element={<Checkout />} /> */}

{/* <Route element={<PrivateRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route> */}