import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../utils/config";
import { warning } from "../utils/Alert";
import { logout } from "../../redux/slice/userAuthSlice";
import Saree1 from "../../assets/images/saree1.png";
import { productGet } from "../../redux/slice/productSlice";
import { categoryGet } from "../../redux/slice/categorySlice";
import { bannerGet } from "../../redux/slice/bannerSlice";
import { productAttribute } from "../../redux/slice/extraSlice";
import { showCart } from "../../redux/slice/cartSlice";
import { policyGet } from "../../redux/slice/policySlice";
import { settingGet } from "../../redux/slice/settingSlice";

const Header = () => {
  const { isAuth, loginUser } = useSelector((state) => state.userAuth);
  const { category } = useSelector((state) => state.category);
  const { colorDetails, febricDetails } = useSelector((state) => state.extra);
  const { totalProduct } = useSelector((state) => state.cart);
  const { setting } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(productAttribute());
    dispatch(categoryGet());
    dispatch(bannerGet());
    dispatch(productGet());
    dispatch(policyGet());
  }, []);
  useEffect(() => {
    dispatch(settingGet());
  }, []);

  useEffect(() => {
    if (loginUser._id) {
      dispatch(showCart(loginUser._id));
    }
  }, [loginUser._id]);

  const colorDetail = ["Red", "Blue", "Green", "Yellow"];

  const handleLogout = () => {
    const data = warning(
      "",
      "Logout To this Website?",
      "Logout",
      "ri-logout-circle-r-line"
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(logout());
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };
  const [stopNav, setStopNav] = useState("");

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 70) {
        setStopNav("stopNavigation");
      } else {
        setStopNav("");
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const option = {
    isAuth,
    loginUser,
    handleLogout,
    totalProduct,
  };
  console.log("category", category);

  const handleShowCategory = (id) => {
    navigate("/shop/sareeCategory", { state: id });
  };

  const handleShowFebric = (id) => {
    navigate("/shop/febric", { state: id });
  };

  const handleShowColor = (id) => {
    const newColor = id?.split("#");
    navigate("/shop/color", { state: `%23${newColor[1]}` });
  };

  return (
    <>
      <MenuScript />
      <div className="mainHeader bg-light">
        {/* <div className="upperHeader bg-second">
          <div className="container">
            <div className="row align-content-center text-light">
              <div className="col-sm-8 col-12 m0 align-self-center">
                <div className="offers fw-bold ">{setting?.headerOffer}</div>
              </div>
              <div className="col-sm-4 col-12 m0">
                <div className="extraNav d-flex justify-content-sm-end justify-content-center">
                  <ul className="mainExtraMenu d-flex text-center m-0 p-0">
                    <li className="align-self-center extraSub">
                      {isAuth ? (
                        <div className="mainProfile d-flex align-items-center text-start">
                          <div className="profileImage m7-right midBox">
                            <img
                              src={baseURL + loginUser.profileImage}
                              alt="profile Image"
                            />
                          </div>
                          <span className="text-capitalize">
                            {loginUser.name}
                          </span>
                          <ul className="profileSub">
                            <li>
                              <Link to={"/userProfile"}>
                                <span>
                                  <i class="ri-user-3-line m10-right"></i>
                                </span>
                                <span>Profile</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/wishlist"}>
                                <span>
                                  <i class="ri-heart-3-line m10-right"></i>
                                </span>
                                <span>Wishlist</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/order"}>
                                <span>
                                  <i class="ri-order-play-fill m10-right"></i>
                                </span>
                                <span>Order</span>
                              </Link>
                            </li>
                            <li>
                              <Link onClick={handleLogout}>
                                <span>
                                  <i class="ri-logout-circle-r-line m10-right"></i>
                                </span>
                                <span>Logout</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <Link to="/login">Log in</Link>
                      )}
                    </li>

                    <li>
                      <a href="#">Help</a>
                    </li>
                    {isAuth && (
                      <li className="myAnimation">
                        <Link to="/cart">
                          <div className="position-relative">
                            <i class="bi bi-bag"></i>
                            <div className="cartIcon fs-10">
                              {totalProduct || 0}
                            </div>
                          </div>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className={`middleHeader bg-second ${stopNav}`}>
          <div className="container position-relative">
            <div className="logoHeader row justify-content-between align-items-center">
              {/* Mobile Menu With Toggle */}
              <div className="col-2 d-lg-none text-end d-flex align-items-center">
                {/* Profile */}
                {/* <Profile {...option} /> */}
                <div className="menuToggle me-2" id="menuTaggle">
                  <i
                    class="fa-solid fa-bars border-light-2 text-light p-1"
                    style={{ borderRadius: "5px" }}
                  ></i>
                </div>
                {/* Mobile Menu */}
                <ul className="mobile-mainMenu m-0 p-0 w-100 py-2 text-start">
                  <li>
                    <span>
                      <Link to="/">Home</Link>
                    </span>
                  </li>
                  <li className="noClick">
                    <span>
                      {/* <Link to="#"> */}
                      <div className="headText">
                        <span>Product</span>
                        <span>
                          <i class="ri-arrow-drop-down-line"></i>
                        </span>
                      </div>
                      {/* </Link> */}
                    </span>
                    <ul className="subMenu">
                      <li className="noClick">
                        <span>
                          {/* <Link to="#"> */}
                          <div className="headText">
                            <span>Category</span>
                            <span>
                              <i class="ri-arrow-drop-down-line"></i>
                            </span>
                          </div>
                          {/* </Link> */}
                        </span>
                        <ul className="petaMenu">
                          {category.length > 0 &&
                            category?.map((res) => {
                              return (
                                <li
                                  className="text-capitalize"
                                  onClick={() => handleShowCategory(res._id)}
                                >
                                  {res.categoryName}
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                      <li className="noClick">
                        <span>
                          {/* <Link to="#"> */}
                          <div className="headText">
                            <span>Febric</span>
                            <span>
                              <i class="ri-arrow-drop-down-line"></i>
                            </span>
                          </div>
                          {/* </Link> */}
                        </span>
                        <ul className="petaMenu">
                          {febricDetails.length > 0 &&
                            febricDetails?.map((res) => {
                              return (
                                <li
                                  className="text-capitalize"
                                  onClick={() => handleShowFebric(res)}
                                >
                                  {res}
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                      <li className="noClick">
                        <span>
                          {/* <Link to="#"> */}
                          <div className="headText">
                            <span>Color</span>
                            <span>
                              <i class="ri-arrow-drop-down-line"></i>
                            </span>
                          </div>
                          {/* </Link> */}
                        </span>
                        <ul className="petaMenu sidePetaMenu">
                          {colorDetails.length > 0 &&
                            colorDetails?.map((res) => {
                              return (
                                <li
                                  className="text-capitalize p5-x"
                                  onClick={() => handleShowColor(res)}
                                >
                                  <div
                                    className="height-23 width-23 rounded-2"
                                    style={{ backgroundColor: res }}
                                  ></div>
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="noClick">
                    <span>
                      {/* <Link to="#"> */}
                      <div className="headText">
                        <span>Collection</span>
                        <span>
                          <i class="ri-arrow-drop-down-line"></i>
                        </span>
                      </div>
                      {/* </Link> */}
                    </span>
                    <ul className="subMenu">
                      <li>
                        <span>
                          <Link to="/shop">All Product</Link>
                        </span>
                      </li>
                      <li>
                        <span>
                          <Link to="/shop/weddingCollection">
                            Wedding Collection
                          </Link>
                        </span>
                      </li>
                      <li>
                        <span>
                          <Link to="/shop/newCollection">New Collection</Link>
                        </span>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <span>
                      <Link to="/about">About Us</Link>
                    </span>
                  </li>
                  <li>
                    <span>
                      <Link to="/contact">Contect Us</Link>
                    </span>
                  </li>
                </ul>
              </div>
              {/* Logo */}
              <div className="col-lg-2 col-5 d-flex justify-content-xl-start justify-content-center  align-items-center">
                <div className="logo width-mn-sm-150 width-mn-120">
                  <Link to="/">
                    <img src={baseURL + setting.logo} alt="THE VINI" />
                  </Link>
                </div>
              </div>
              {/* web Menu */}
              <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-12 col-8 d-lg-flex d-none justify-content-xl-end justify-content-lg-center justify-content-end m0-bottom">
                <nav>
                  <div className="mainNavigation d-flex">
                    {/* Web Menu */}
                    <ul className="mainMenu justify-content-center m-0 d-lg-flex d-none">
                      <li>
                        <span>
                          <Link to="/">
                            <div className="headText">Home</div>
                          </Link>
                        </span>
                      </li>

                      <li>
                        <span>
                          <Link>
                            <div className="headText">
                              <span>Product</span>
                              <span>
                                <i class="ri-arrow-drop-down-line"></i>
                              </span>
                            </div>
                          </Link>
                        </span>
                        <div className="megaMenu">
                          <div className="">
                            <div className="col">
                              <h5 className="megaTitle">Category</h5>
                              <ul>
                                {category.length > 0 &&
                                  category?.map((res) => {
                                    return (
                                      <li
                                        className="text-capitalize"
                                        onClick={() =>
                                          handleShowCategory(res._id)
                                        }
                                      >
                                        {res.categoryName}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            <div className="col">
                              <h5 className="megaTitle">Febric</h5>
                              <ul>
                                {febricDetails.length > 0 &&
                                  febricDetails?.map((res) => {
                                    return (
                                      <li
                                        className="text-capitalize"
                                        onClick={() => handleShowFebric(res)}
                                      >
                                        {res}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                            {/* <div className="col">
                              <h5 className="megaTitle">Colors</h5>
                              <ul className="d-flex flex-wrap">
                                {colorDetails.length > 0 &&
                                  colorDetails?.map((res) => {
                                    return (
                                      <li
                                        className="text-capitalize p5-x"
                                        onClick={() => handleShowColor(res)}
                                      >
                                        <div
                                          className="height-23 width-23 rounded-2"
                                          style={{ backgroundColor: res }}
                                        ></div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </li>

                      <li>
                        <span>
                          <Link to="#">
                            <div className="headText">
                              <span>Collection</span>
                              <span>
                                <i class="ri-arrow-drop-down-line"></i>
                              </span>
                            </div>
                          </Link>
                        </span>
                        <ul className="subMenu">
                          <li>
                            <span>
                              <Link to="/shop">All Product</Link>
                            </span>
                          </li>
                          <li>
                            <span>
                              <Link to="/shop/weddingCollection">
                                Wedding Collection
                              </Link>
                            </span>
                          </li>
                          <li>
                            <span>
                              <Link to="/shop/newCollection">
                                New Collection
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <span>
                          <Link to="/about">
                            <div className="headText">About Us</div>
                          </Link>
                        </span>
                      </li>
                      <li>
                        <span>
                          <Link to="/contact">
                            <div className="headText">Contect Us</div>
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="col-xl-4 col-lg-2 col-sm-3 col-12 m0 d-lg-block d-none">
                <div className="extraNav d-flex justify-content-sm-end justify-content-center d-xl-block d-none">
                  <ul className="mainExtraMenu d-flex text-center m-0 p-0">
                    <li className="align-self-center extraSub">
                      {isAuth ? (
                        <div className="mainProfile d-flex align-items-center text-start">
                          <div className="profileImage m7-right midBox hw-sm-30 hw-25">
                            <img
                              src={baseURL + loginUser.profileImage}
                              alt="profile Image"
                            />
                          </div>
                          <span className="text-capitalize profileName fs-xl-18 fs-sm-16 fs-14 text-overflow-1">
                            {loginUser.name}
                          </span>
                          <ul className="profileSub">
                            <li>
                              <Link to={"/userProfile"}>
                                <span>
                                  <i class="ri-user-3-line m10-right"></i>
                                </span>
                                <span>Profile</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"#"}>
                                <span>
                                  <i class="ri-heart-3-line m10-right"></i>
                                </span>
                                <span>Help</span>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/order"}>
                                <span>
                                  <i class="ri-order-play-fill m10-right"></i>
                                </span>
                                <span>Order</span>
                              </Link>
                            </li>
                            <li>
                              <Link onClick={handleLogout}>
                                <span>
                                  <i class="ri-logout-circle-r-line m10-right"></i>
                                </span>
                                <span>Logout</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <>
                          <Link to="/login">Log in</Link>
                        </>
                      )}
                    </li>

                    {!isAuth && (
                      <li>
                        <Link to={`#`}>Help</Link>
                      </li>
                    )}
                    {isAuth && (
                      <>
                        <li className="myAnimation">
                          <Link to={"/wishlist"}>
                            <span>
                              <i class="ri-heart-3-line fs-20"></i>
                            </span>
                          </Link>
                        </li>
                        <li className="myAnimation">
                          <Link to="/cart">
                            <div className="position-relative">
                              <i class="bi bi-bag"></i>
                              <div className="cartIcon fs-10">
                                {totalProduct || 0}
                              </div>
                            </div>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                {/* Profile */}
                <div className="mainProfile d-xl-none d-lg-block d-none">
                  <Profile {...option} />
                </div>
              </div>
              <div className="col-md-4 col-2 d-lg-none text-end d-flex align-items-center justify-content-end">
                {/* Profile */}
                <Profile {...option} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

export const Profile = (props) => {
  const { isAuth, loginUser, handleLogout, totalProduct } = props;

  return (
    <li className="align-self-center extraSub pExtra m15-right text-center">
      {isAuth ? (
        <div className="mainProfile d-flex align-items-center text-start justify-content-end">
          <div className="profileImage m7-right midBox hw-sm-30 hw-25">
            <img src={baseURL + loginUser.profileImage} alt="profile Image" />
          </div>
          <span className="text-capitalize profileName fs-xl-18 fs-sm-16 fs-14 text-overflow-1">
            {loginUser.name}
          </span>
          <ul className="profileSub">
            <li>
              <Link to={"/userProfile"}>
                <span>
                  <i class="ri-user-3-line m10-right"></i>
                </span>
                <span>Profiles</span>
              </Link>
            </li>
            <li>
              <Link to={"/wishlist"}>
                <span>
                  <i class="ri-heart-3-line m10-right"></i>
                </span>
                <span>Wishlist</span>
              </Link>
            </li>
            <li>
              <Link to={"/order"}>
                <span>
                  <i class="ri-order-play-fill m10-right"></i>
                </span>
                <span>Order</span>
              </Link>
            </li>
            <li>
              <Link to={"/cart"}>
                <span>
                  <span className="position-relative m10-right">
                    <i class="bi bi-bag"></i>
                    <span
                      className="cartIcon fs-10 bg-second text-light"
                      style={{ top: "-3px", right: "-7px" }}
                    >
                      {totalProduct || 0}
                    </span>
                  </span>
                </span>
                <span>Cart</span>
              </Link>
            </li>
            <li>
              <Link to={"/"}>
                <span>
                  <i class="ri-question-line m10-right"></i>
                </span>
                <span>Help</span>
              </Link>
            </li>
            <li>
              <Link onClick={handleLogout}>
                <span>
                  <i class="ri-logout-circle-r-line m10-right"></i>
                </span>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      ) : (
        <Link to="/login">
          <i className="ri-user-3-line text-light"></i>
        </Link>
      )}
    </li>
  );
};

export const MenuScript = () => {
  useEffect(() => {
    // ================ Mobile menu toggale ==================
    // Main Menu
    const handleSidebar = () => {
      $(".mobile-mainMenu").slideToggle();
    };
    $(".mobile-mainMenu").hide();
    $("#menuTaggle").on("click", handleSidebar);

    // Sub Menu
    const handleSubSidebar = (event) => {
      $(".mobile-mainMenu .subMenu")
        .not($(event.currentTarget).next())
        .slideUp();
      $(event.currentTarget).next().slideToggle();
    };
    $(".mobile-mainMenu .subMenu").hide();
    $(".mobile-mainMenu > li > span").on("click", handleSubSidebar);

    // Peta Menu
    const handlePetaSidebar = (event) => {
      $(".mobile-mainMenu .petaMenu")
        .not($(event.currentTarget).next())
        .slideUp();
      $(event.currentTarget).next().slideToggle();
    };

    $(".mobile-mainMenu .petaMenu").hide();
    $(".mobile-mainMenu .subMenu > li > span").on("click", handlePetaSidebar);

    const handleSlider = () => {
      $(".mobile-mainMenu").hide();
      $(".mobile-mainMenu .subMenu").hide();
      $(".mobile-mainMenu .petaMenu").hide();
    };
    $(".mobile-mainMenu li:not(.noClick)").on("click", handleSlider);

    return () => {
      // ================ Mobile menu toggale ==================
      $("#menuTaggle").off("click", handleSidebar);
      // $(".mobile-mainMenu > li").off("click", handleSlider);
      $(".mobile-mainMenu > li > span").off("click", handleSubSidebar);
      $(".mobile-mainMenu .subMenu > li > span").off(
        "click",
        handlePetaSidebar
      );
    };
  }, []);

  return null;
};
