import React, { useEffect, useState } from "react";
import Title from "./extra/Title";
import Input from "./extra/Input";
import { submitData } from "./utils/fuction";
import Footer from "./globle/Footer";
import Header from "./globle/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addAddress, deleteAddress, login } from "../redux/slice/userAuthSlice";
import AddressDialogue, { AddressForm } from "./AddressDialogue";
import { closeDialog, openDialog } from "../redux/slice/dialogSlice";
import {
  checkoutPayment,
  getRazorKey,
  paymentVerification,
} from "../redux/slice/paymentSlice";
import { baseURL, key } from "./utils/config";
import Service from "./components/Service";
import Logo from "../assets/images/logo.png";
import { orderSubmit } from "../redux/slice/orderSlice";
import Loader from "./utils/Loader";
import { warning } from "./utils/Alert";
const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const { loginUser, isAuth } = useSelector((state) => state.userAuth);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const { isLoader } = useSelector((state) => state.order);
  let payLoader = useSelector((state) => state.order.isLoader);
  // get cart
  const localCart = localStorage.getItem("cart");
  const parsedLocalCart = JSON.parse(localCart);
  const orderCart = location?.state || parsedLocalCart;

  // get address
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);
  const userAddress = loginUser.address || parsedSessionUserId.address;
  const loginUserData = loginUser || parsedSessionUserId;

  console.log("userAddress", userAddress);
  console.log("userAddress.length", userAddress?.length);

  // Crud Address
  const handleSubmit = (e) => {
    const addData = submitData(e);
    console.log("addData", addData);
    if (addData) {
      dispatch(addAddress({ ...addData, userId: parsedSessionUserId._id }));
    }
    dispatch(closeDialog());
  };

  useEffect(() => {
    if (!localCart) {
      navigate(-1);
    }
  }, [location]);
  useEffect(() => {
    if (location?.state) {
      localStorage.setItem("cart", JSON.stringify(location?.state));
    }
  }, [location?.state]);
  const [addressNumber, setAddressNumber] = useState(0);
  const [choiceMethod, setChoiseMethod] = useState(0);
  useEffect(() => {
    console.log("addressNumber", addressNumber);
  }, [addressNumber]);

  const makePayment = async () => {
    payLoader = true;
    debugger;
    const checkoutDetails = {
      address: userAddress[addressNumber],
      product: orderCart,
      user: loginUserData,
    };
    // get razorpay key

    const { razorKey } = await dispatch(getRazorKey()).unwrap();
    debugger;
    payLoader = false;
    console.log("razorKey", razorKey);

    // checkout data
    console.log("checkoutDetails", checkoutDetails);

    const { order } = await dispatch(
      checkoutPayment({ amount: orderCart.finalPrice })
    ).unwrap();

    console.log("order", order);

    var options = {
      key: razorKey,
      amount: order.amount,
      currency: "INR",
      name: "Shwini",
      description: "Test Transaction",
      image: Logo,
      order_id: order.id,
      handler: async (response) => {
        const data = await dispatch(
          paymentVerification({ ...response, ...checkoutDetails })
        ).unwrap();
        if (data) {
          navigate("/order");
          localStorage.removeItem("cart");
        }
      },
      prefill: {
        name: loginUserData.name,
        email: loginUserData.email,
        contact: loginUserData.mobileNo,
      },
      notes: {
        address: checkoutDetails.address,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();

    console.log("order", order);
  };

  const makeCod = async () => {
    console.log("localCart", localCart);
    console.log("parsedLocalCart", parsedLocalCart);
    const data = warning(
      "Confirm Order",
      "Are You Sure Confirm Order?",
      "Confirm",
      "ri-truck-line",
      "prime"
    );
    data
      .then(async (logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          const checkoutDetails = {
            address: userAddress[addressNumber],
            product: orderCart,
            user: loginUserData,
          };

          console.log("checkoutDetails", checkoutDetails);
          const payload = {
            ...checkoutDetails,
            isCod: choiceMethod == 0 ? false : true,
          };

          const response = await dispatch(orderSubmit(payload));

          if (response?.payload?.status) {
            navigate("/order");
            localStorage.removeItem("cart");
          } else {
            alert(response?.payload?.message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Header />
      <section id="Checkout">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-12 order-md-0 order-1">
              <div className="d-flex justify-content-between">
                <Title
                  name={`Billing Address`}
                  className={`fw-500 p10-bottom`}
                />
                {userAddress?.length > 0 && (
                  <div className="primeButton">
                    <button
                      className="p10-x"
                      onClick={() => {
                        dispatch(openDialog({ type: "address" }));
                      }}
                    >
                      Add Address
                    </button>
                  </div>
                )}
              </div>
              <div className="mianAddress">
                {userAddress?.length > 0 ? (
                  <>
                    {userAddress?.map((res, i) => {
                      return (
                        <UserAddress
                          data={res}
                          ids={i}
                          key={i}
                          userId={parsedSessionUserId}
                          setAddressNumber={setAddressNumber}
                          addressNumber={addressNumber}
                        />
                      );
                    })}
                    <Title name={`Payment Getway`} className={`fw-500 p15-y`} />
                    <div className="mainPaymentGetway">
                      <div className="w-100">
                        <div className="row justify-content-center paymentGetway">
                          <div className="col-12">
                            <label
                              htmlFor="getWay1"
                              className="getWay d-flex justify-content-between"
                              onClick={() => setChoiseMethod(0)}
                            >
                              <Input
                                type={`radio`}
                                id={`getWay1`}
                                label={`Online Pay`}
                                name={`getWay`}
                                value={`getWay1`}
                                checked={choiceMethod == 0 && true}
                              />
                              <div className="payIcon">
                                <i class="fa-brands fa-google-pay"></i>
                                <i class="fa-brands fa-cc-visa"></i>
                                <i class="fa-solid fa-credit-card"></i>
                                <i class="fa-solid fa-building-columns"></i>
                                <i class="fa-solid fa-wallet"></i>
                              </div>
                            </label>
                          </div>
                          <div className="col-12">
                            <label
                              htmlFor="getWay2"
                              className="getWay d-flex justify-content-between"
                              onClick={() => setChoiseMethod(1)}
                            >
                              <Input
                                type={`radio`}
                                id={`getWay2`}
                                label={`Cash On Delivery`}
                                name={`getWay`}
                                value={`getWay2`}
                                checked={choiceMethod == 1 && true}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="primeButton">
                      {addressNumber >= 0 && choiceMethod >= 0 ? (
                        choiceMethod == 0 ? (
                          <button onClick={() => makePayment()}>
                            Checkout - Online Payment
                          </button>
                        ) : (
                          <button onClick={() => makeCod()}>
                            Checkout - COD
                          </button>
                        )
                      ) : (
                        <button disabled>Checkout</button>
                      )}
                    </div>
                  </>
                ) : (
                  <form id="checkoutForm" onSubmit={handleSubmit}>
                    <AddressForm />
                    <div className="primeButton">
                      <button type="submit">Add Address</button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-12">
              <div className="showToCart p20">
                <Title name={`Order Summary`} className={`title`} />
                <div className="showMyCart m25-top">
                  {orderCart?.allProduct?.map((res) => {
                    return <ShowMyCart data={res} />;
                  })}
                </div>
                <div className="countTotalOrder m10-top">
                  <ul className="mainCountOrder p10 border-bottom-gray-1">
                    <li>
                      <span>
                        Sub Total{" "}
                        <span className="text-gray">
                          ({orderCart.productCount} items)
                        </span>{" "}
                      </span>
                      <span>₹{orderCart.finalOldPrice}</span>
                    </li>
                    <li>
                      <span>Discount</span>
                      <span className="text-success">
                        -₹{orderCart.finalSavePrice}
                      </span>
                    </li>
                    <li>
                      <span>Shipping</span>
                      <span className="text-success">Free</span>
                    </li>
                    <li>
                      <span>Discount</span>
                      <span className="text-success"></span>
                    </li>
                  </ul>
                  <ul className="mainCountOrder productTotal p10-x">
                    <li>
                      <span>Total</span>
                      <span>₹{orderCart.finalPrice}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {dialogue && dialogueType === "address" && <AddressDialogue />}
        {(isLoader || payLoader) && <Loader />}
      </section>
      {/* <PaymentMethod /> */}
      <Service />
      <Footer />
    </>
  );
};

export default Checkout;

// ==================== User Address ======================

export const UserAddress = ({
  data,
  ids,
  userId,
  addressNumber,
  setAddressNumber,
  isSelect,
}) => {
  console.log("data", data);
  const handleDeleteAdd = (id) => {
    dispatch(deleteAddress({ userId: userId._id, addressId: id }));
  };

  const dispatch = useDispatch();

  const handleAddEdit = () => {
    dispatch(
      openDialog({
        type: "address",
        data: {
          ...data,
          country: data.details.country,
          pincode: data.details.pincode,
          city: data.details.city,
          state: data.details.state,
          socName: data.details.socName,
        },
      })
    );
  };

  return (
    <label
      htmlFor={`address${ids}`}
      className="d-block"
      onClick={() => setAddressNumber(ids)}
    >
      <div className="allAddressShow d-flex justify-content-between text-capitalize position-relative fs-lg-16 fs-md-14 fs-12">
        <div
          className="actionButon position-absolute top-1  cursor-pointer"
          style={{ top: "10px", right: "30px" }}
        >
          <span
            onClick={() => handleDeleteAdd(data?._id)}
            className="text-second p3 m2 fs-lg-20 fs-md-18 fs-16"
          >
            <i class="ri-delete-bin-6-line"></i>
          </span>
          <span
            onClick={handleAddEdit}
            className="text-second p3 m2 fs-lg-20 fs-md-18 fs-16"
          >
            <i class="ri-edit-box-line"></i>
          </span>
        </div>
        <div className="addDetails">
          <div className="d-inline-flex">
            <h5 className="fs-lg-18 fs-md-16 fs-14">{data?.fullName}</h5>
            <span className="m10-left fs-lg-12 fs-md-10 fs-8 bg-gray h-100 p5-x p2-y text-light">
              {data?.type}
            </span>
          </div>
          <p className="text-success fw-bold">+91 {data?.phone}</p>
          <p>{data?.details.socName}</p>
          <p>
            <span>{data?.details.city}</span>{" "}
            <span>- {data?.details.pincode}</span>
          </p>
          <p>
            {data?.details.state}, {data?.details.country}
          </p>
        </div>
        {!isSelect && (
          <Input
            type={`radio`}
            id={`address${ids}`}
            name={`address`}
            value={ids}
            checked={addressNumber == ids && true}
            inputClass={`m20-right`}
          />
        )}
      </div>
    </label>
  );
};

// ======================== ShoMyCart =======================
export const ShowMyCart = (props) => {
  const {
    productImage,
    title,
    productCount,
    finalPrice,
    finalOldPrice,
    productCode,
    price,
    oldPrice,
    productSize,
  } = props?.data;
  console.log("props?.data", props?.data);
  return (
    <div className="d-flex align-items-center border-bottom-gray2-1 m10-top">
      <div className="cartImage col-3 width-lg-100 width-80 height-lg-150 height-md-130 height-115">
        <img src={baseURL + productImage[0]} alt="cart" />
      </div>
      <div className="cartDetails col-9">
        <h5>
          {title}({productSize})
        </h5>
        <div className="productPrice m4-top">
          <span className="fw-600 me-2">₹{price}</span>
          <span className="fw-600 text-gray">
            <del>₹{oldPrice}</del>
          </span>
        </div>
        <div className="productQUT m4-top">
          <span className="fw-600">QUT : {productCount}</span>
        </div>
        <div className="productCode productQUT text-success m4-top">
          <span className="fw-600">#{productCode}</span>
        </div>
        <div className="productPrice m4-top">
          <span className="fw-600">Total : </span>
          <span className="fw-600 me-2">₹{finalPrice}</span>
          <span className="fw-600 text-gray">
            <del>₹{finalOldPrice}</del>
          </span>
        </div>
      </div>
    </div>
  );
};

// export const PaymentMethod = () => {
//   const dispatch = useDispatch();
//   return (
//     <div className="mainPaymentGetway">
//       <div className="dialog">
//         <div className="w-100">
//           <div className="row justify-content-center">
//             <div className="col-xl-5 col-md-9 col-11">
//               <div className="paymentGetway mainDiaogBox  bg-light p30">
//                 <div className="row justify-content-between align-items-center formHead">
//                   <div className="col-8 ">
//                     <h2 className="text-second m0">Payment Method</h2>
//                   </div>
//                   <div className="col-4">
//                     <div
//                       className="closeButton"
//                       onClick={() => {
//                         dispatch(closeDialog());
//                       }}
//                     >
//                       <i className="ri-close-line"></i>
//                     </div>
//                   </div>
//                   <div className="col-12 m15-top">
//                     <label
//                       htmlFor="getWay1"
//                       className="getWay d-flex justify-content-between"
//                     >
//                       <Input
//                         type={`radio`}
//                         id={`getWay1`}
//                         label={`Online Pay`}
//                         name={`getWay`}
//                         value={`getWay1`}
//                       />
//                       <i class="fa-brands fa-cc-visa"></i>
//                     </label>
//                   </div>
//                   <div className="col-12">
//                     <label
//                       htmlFor="getWay2"
//                       className="getWay d-flex justify-content-between"
//                     >
//                       <Input
//                         type={`radio`}
//                         id={`getWay2`}
//                         label={`Cash On Delivery`}
//                         name={`getWay`}
//                         value={`getWay2`}
//                       />
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
export const AddATMCard = () => {
  return (
    <div className="row">
      <div className="col-12">
        <Input
          type={`text`}
          id={`holderName`}
          label={`Card Holder Name`}
          placeholder={`Card Holder Name`}
          name={`holderName`}
          errorMessage={`Enter Holder Name`}
        />
      </div>
      <div className="col-12">
        <Input
          type={`number`}
          id={`cardNumber`}
          label={`Card Number`}
          placeholder={`0 0 0 0  0 0 0 0  0 0 0 0  0 0 0 0  `}
          name={`cardNumber`}
          errorMessage={`Enter Card Number`}
          // validation={`/^(1[89]|[2-9]\d)$/gm`}
          // validationError={`Valid Only 18+`}
        />
      </div>
      <div className="col-6">
        <Input
          type={`text`}
          id={`lastDate`}
          label={`Expire Date`}
          placeholder={`Expire Date`}
          name={`lastDate`}
          errorMessage={`Enter Expire Date`}
        />
      </div>
      <div className="col-6">
        <Input
          type={`number`}
          id={`CVV`}
          label={`CVV`}
          placeholder={`0 0 0`}
          name={`CVV`}
          errorMessage={`Enter CVV`}
          // validation={`/^(1[89]|[2-9]\d)$/gm`}
          // validationError={`Valid Only 18+`}
        />
      </div>
      <div className="col-12">
        <div className="col-12">
          <Input
            type={`checkbox`}
            id={`saveCard`}
            label={`Save card details for future payment method`}
            lebalClass={`text-gray fw-400`}
            name={`saveCard`}
            value={true}
          />
        </div>
      </div>
      <div className="col-12">
        <button type="submit" className="mk-btn w-100">
          CONTINUE
        </button>
      </div>
    </div>
  );
};
