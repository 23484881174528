import SareeDetails from "./components/SareeDetails";
import BestSeller from "./components/BestSeller";
import Banner from "./extra/Banner";
import Service from "./components/Service";
import Header from "./globle/Header";
import Footer from "./globle/Footer";
import SuggetionProduct from "./components/SuggetionProduct";

const SareeProfile = () => {
  return (
    <div className="scrollPage" id="SareeProfileMain">
      <Header />
      <SareeDetails />
      <SuggetionProduct />
      {/* <Banner /> */}
      <Service />
      <Footer />
    </div>
  );
};

export default SareeProfile;
