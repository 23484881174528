import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import Title from '../extra/Title';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { categoryGet } from '../../redux/slice/categorySlice';
import { baseURL } from '../utils/config';
function Category() {

  const options = {
    responsive: {
      0: {
        items: 2
      },
      370: {
        items: 3
      },
      768: {
        items: 4
      },
      1300: {
        items: 6
      },
    }
  }

  const dispatch = useDispatch()

  const { category, isSkeleton } = useSelector((state) => state.category)

  useEffect(() => {
    dispatch(categoryGet())
  }, []);

  const navigate = useNavigate()
  const handleShowCategory = (id) => {
    navigate("/shop/sareeCategory", { state: id })
  }

  return (
    <section id='Category' className='carousel-mini'>
      <div className='container'>
        <Title name={`Shop by saree`} />

        <OwlCarousel className='owl-theme' {...options} nav navClass={['fa-solid fa-angle-left prev', 'fa-solid fa-angle-right next']}>
          {
            category?.length > 0 ? category?.map((res) => {
              return (
                <div class='item'>
                  <div className="categoryItems hw-lg-180 hw-sm-140 hw-100 " onClick={() => handleShowCategory(res._id)}>
                    <img src={baseURL + res.image} alt="" />
                  </div>
                  <p className='categoryName text-capitalize fs-lg-20 fs-sm-16 fs-14'>{res.categoryName}</p>
                </div>
              )
            }) : [...Array(6)]?.map((res) => {
              return (
                <div class='item'>
                  <div className="categoryItems skeleton hw-lg-180 hw-sm-140 hw-100 ">
                  </div>
                  <p className='categoryName skeleton'></p>
                </div>
              )
            })
          }

        </OwlCarousel>
      </div>
    </section>
  )
}
export default Category;