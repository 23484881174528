import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { invoiceDownload, userOrder } from "../redux/slice/orderSlice";
import CartImage from "../assets/images/cart.png";
import Header from "./globle/Header";
import Footer from "./globle/Footer";
import { baseURL } from "./utils/config";
import RatingDialogue from "./RatingDialogue";
import { openDialog } from "../redux/slice/dialogSlice";
import OrderShow from "./OrderShow";
import moment from "moment";
import Pagination from "./extra/Pagination";
import Title from "./extra/Title";
import { DateRange, DateRangePicker } from "react-date-range";

const Order = () => {
  const dispatch = useDispatch();
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);
  const { order, isSkeleton, totalCount } = useSelector((state) => state.order);

  // Pagination Both
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [counts, setCounts] = useState(0);
  const [classes, setClasses] = useState("hidPicker");

  const [date, setDate] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);
  const payload = {
    page,
    limit: rowsPerPage,
    startDate: date[0].startDate
      ? moment(date[0].startDate).format("YYYY-MM-DD")
      : "",
    endDate: date[0].endDate
      ? moment(date[0].endDate).format("YYYY-MM-DD")
      : "",
  };

  useEffect(() => {
    dispatch(userOrder({ userId: parsedSessionUserId._id, ...payload }));
  }, [page, date]);

  const handleOpenPicker = () => {
    if (classes) {
      setClasses("");
    } else {
      setClasses("hidPicker");
    }
  };

  useEffect(() => {
    if (counts % 2 == 0) {
      setClasses("hidPicker");
    }
  }, [counts]);

  return (
    <div>
      <Header />
      <div className="mainOrder m30-top">
        <div className="container">
          <div className="row justify-content-center m20-bottom">
            <div className="col-xxl-9 col-xl-10 col-12 d-flex justify-content-between align-items-center">
              <Title name={`Your Order`} className={`m0`} />
              <div className="datePickerMain">
                <div
                  onClick={handleOpenPicker}
                  className="showDate primeButton fs-lg-16 fs-md-14 fs-12"
                >
                  <button type="button" className="p10">
                    {date[0].startDate != "" || date[0].endDate != ""
                      ? `
                        ${moment(date[0].startDate).format(
                          "DD MMM YYYY"
                        )} - To - ${moment(date[0].endDate).format(
                          "DD MMM YYYY"
                        )}`
                      : "SELECT DATE"}
                  </button>
                </div>
                <div className={`datePickerBox ${classes}`}>
                  <div className="d-md-block d-none">
                    <DateRangePicker
                      editableDateInputs={true}
                      onChange={(item) => {
                        setDate([item.selection]);
                        setCounts(counts + 1);
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={date}
                    />
                  </div>
                  <div className="d-md-none">
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => {
                        setDate([item.selection]);
                        setCounts(counts + 1);
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={date}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {!isSkeleton ? (
              order?.length > 0 ? (
                order?.map((res, index) => {
                  console.log(
                    "res?.data?.status?.toLowercase()",
                    res?.data?.status
                  );
                  return (
                    <div className="col-xxl-9 col-xl-10 col-12">
                      <OrderBox key={index} data={res} />
                    </div>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  <img src={CartImage} alt="" className="noData" />
                </div>
              )
            ) : (
              [...Array(4)]?.map(() => {
                return (
                  <div className="col-xxl-9 col-xl-10 col-12">
                    <OrderBoxSkeleton />
                  </div>
                );
              })
            )}
          </div>
        </div>

        <Pagination
          type={"server"}
          serverPage={page}
          setServerPage={setPage}
          serverPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          totalData={totalCount}
        />
      </div>
      <Footer />
    </div>
  );
};
export default Order;

export const OrderBox = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  // const [count, setCount] = useState(data?.productCount)

  const date = moment();

  const statusLabels = {
    NEW: "Pending",
    INVOICED: "Pending",
    "PICKUP ERROR": "Confirm",
    "PICKUP SCHEDULED": "Confirm",
    "PICKUP GENERATED": "Confirm",
    "PICKED UP": "Confirm",
    "IN TRANSIT": "Confirm",
    "REACHED AT DESTINATION HUB": "Confirm",
    "FDM PREPARED": "Confirm",
    "OUT FOR DELIVERY": "Out of Delivery",
    DELIVERED: "Delivered",
    "CANCELLATION REQUESTED": "Cancellation Request",
    CANCELED: "Cancelled",
  };

  const status = data?.confirmStatus?.toUpperCase() || "";

  const finalStatus = statusLabels[status] || "Confirm";

  const handleDownloadInvoice = async (ids) => {
    const downloadData = await dispatch(invoiceDownload({ ids }));
    if (downloadData.payload.status) {
      window.open(downloadData.payload.myInvoice.invoice_url, "_blank");
    }
  };

  return (
    <div className="cartBox m10-bottom border-prime-3 ">
      <div className="customerOrderDetails p10 bg-success-light border-bottom-prime-3 d-sm-block d-none">
        <div className="row justify-content-between">
          <div className="col-md-2 col-4 order-md-0 order-4">
            <div className="orderPlaced text-md-start text-end">
              <div className="orderTitle fs-14 fw-600 text-prime">
                ORDER PLACED
              </div>
              <div className="orderDes fs-14 fw-600">
                {moment(data.createdAt).format("DD MMMM YYYY")}
              </div>
            </div>
          </div>
          <div className="col-md-2 col-4 order-md-1 order-3">
            <div className="shipTo text-md-start text-center">
              <div className="orderTitle fs-14 fw-600 text-prime">SHIP TO</div>
              <div className="orderDes fs-14 fw-600 text-links position-relative">
                <span>
                  {" "}
                  {data?.address?.fullName.length > 12
                    ? data?.address?.fullName.substring(0, 12) + "..."
                    : data?.address?.fullName}
                </span>
                <i class="ri-arrow-drop-down-line"></i>
                <div className="orderDetailsBox bg-light text-dark p20 rounded-2 position-absolute fs-13">
                  <h6 className="text-nowrap text-links">
                    {data?.address?.fullName}
                  </h6>
                  <p>{data?.address?.details}</p>
                  <p className="text-uppercase text-nowrap text-links">
                    {data?.address?.city} {data?.address?.state}{" "}
                    {data?.address?.pincode}
                  </p>
                  <p>{data?.address?.phone}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-4 order-2">
            <div className="placedBy">
              <div className="orderTitle fs-14 fw-600 text-prime">
                PLACED BY
              </div>
              <div className="orderDes fs-14 fw-600 text-links position-relative">
                <span>
                  {" "}
                  {data?.placeByName?.length > 12
                    ? data?.placeByName?.substring(0, 12) + "..."
                    : data?.placeByName}
                </span>
                <i class="ri-arrow-drop-down-line"></i>
                <div className="orderDetailsBox bg-light text-dark p20 rounded-2 position-absolute">
                  <h6 className="text-nowrap text-links">
                    {data?.placeByName}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-4 order-md-3 order-0 m0-md-bottom m10-bottom">
            <div className="totalPrice">
              <div className="orderTitle fs-14 fw-600 text-prime">TOTAL</div>
              <div className="orderDes fs-14 fw-600">
                ₹{" "}
                {parseInt(data?.productId?.price) *
                  parseInt(data?.productCount)}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-8 order-md-4 order-1 m0-md-bottom m10-bottom">
            <div className="orderId text-end">
              <div className="orderTitle fs-14 fw-600 ">
                <span className="text-prime">ORDER ID </span>
                <span className="fs-14"> #{data?.orderId}</span>
              </div>
              <div className="orderDes fs-14 fw-600 text-links">
                <span className="m5-x">
                  {data.isCod ? "Cash on Delivery" : "Razor Pay"}
                </span>
                {finalStatus == "Delivered" && (
                  <span
                    className="m5-x"
                    onClick={() =>
                      handleDownloadInvoice(data?.shiprocket_order_id)
                    }
                  >
                    Invoice
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="productOrderDetails row align-items-center justify-content-between p30-md-y p10-y p15-x ">
        <div className="col-12 d-md-none m10-bottom">
          <div className="orderTitle fs-12 fw-600">
            <span className="text-prime">ORDER ID </span>
            <span className=""> #{data?.orderId}</span>
          </div>
        </div>
        <div className="col-lg-10 col-md-9 col-12 showCart d-flex align-items-center">
          <div className="cartImage">
            <div className="m20-right width-lg-95 width-75 height-lg-135 height-md-115 height-100 overflow-hidden rounded-3">
              <img
                src={baseURL + data?.productId?.productImage[0]}
                alt="cart"
              />
            </div>
            <div className="orderTitle d-md-none fs-12 fw-600 m5-top">
              {moment(data.createdAt).format("DD MMM YYYY")}
            </div>
          </div>
          <div className="cartDetails">
            <div className="prductTitle fw-600 fs-lg-18 fs-md-16 fs-12 m0-bottom">
              {data?.productId?.title}
            </div>
            <div className="fs-lg-20 fs-xl-16 fs-12  m5-bottom m5-top text-success">
              #{data?.productCode}
            </div>
            <div className="productPrice m4-top fs-xl-16 fs-12">
              <span className="fw-600 me-2 text-links">
                ₹{data?.productId?.price * data?.productCount}
              </span>
              <span className="fw-600 text-gray">
                <del>₹{data?.productId?.oldPrice * data?.productCount}</del>
              </span>
            </div>
            <div className="fw-600 fs-xl-14 fs-12 m10-bottom d-flex align-items-center">
              <span>QUT : {data?.productCount} </span>
              <span>
                <div className="productSizes mt-2 fs-xl-16 fs-14 ms-3">
                  <span className="showSize bg-second text-light m0 p5 fs-12">
                    {data?.productSize}
                  </span>
                </div>
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div
                className={`orderStatus fw-600 d-inline-block  fs-lg-14 fs-xl-12 fs-10 p10-x p5-y text-light 
            ${
              finalStatus == "Pending"
                ? " bg-links"
                : finalStatus == "Confirm"
                ? " bg-blue"
                : finalStatus == "Out of Delivery"
                ? " bg-orange"
                : finalStatus == "Delivered"
                ? " bg-success"
                : finalStatus == "Cancelled"
                ? " bg-danger"
                : finalStatus == "Cancellation Request"
                ? " bg-danger"
                : finalStatus == "" && "bg-blue"
            }
              `}
              >
                {finalStatus}
              </div>
              <div className="fs-15 m10-left fw-600 text-prime">
                {data?.deliveryDate}
              </div>
            </div>
          </div>
        </div>
        <div className=" col-lg-2 col-md-3 col-12 cartButtons">
          <div className="ordersButton row justify-content-between">
            <div
              className="primeButton col-md-12 col-6 m10-top fs-lg-14 fs-12"
              onClick={() => dispatch(openDialog({ type: "orderInfo", data }))}
            >
              <button type="button" className="p10-x">
                Tracking Order
              </button>
            </div>
            <div
              className="primeButton d-md-none d-block m10-top fs-lg-14 fs-12 col-6"
              onClick={() => dispatch(openDialog({ type: "orderInfo", data }))}
            >
              <button
                type="button"
                className="p10-x bg-light border-prime-1 text-prime"
              >
                {data.isCod ? "Cash on Delivery" : "Razor Pay"}
              </button>
            </div>
            {finalStatus == "Delivered" && (
              <div
                className="primeButton col-12 m10-top fs-lg-14 fs-12"
                onClick={() =>
                  dispatch(
                    openDialog({ type: "rating", data: data?.productCode })
                  )
                }
              >
                <button type="button" className="p10-x  bg-warning">
                  Write a product review
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {dialogue && dialogueType === "rating" && <RatingDialogue />}
      {dialogue && dialogueType === "orderInfo" && <OrderShow />}
    </div>
  );
};

export const OrderBoxSkeleton = (props) => {
  return (
    <div className="cartBox m10-bottom border-gray-3 ">
      <div className="customerOrderDetails p10 border-bottom-gray-3 d-sm-block d-none">
        <div className="row justify-content-between">
          <div className="col-md-2 col-4 order-md-0 order-4">
            <div className="orderPlaced text-md-start text-end">
              <div className="orderTitle m5-bottom skeleton height-20 w-60"></div>
              <div className="orderDes skeleton height-20 w-40"></div>
            </div>
          </div>
          <div className="col-md-2 col-4 order-md-1 order-3">
            <div className="shipTo text-md-start text-center">
              <div className="orderTitle m5-bottom skeleton height-20 w-60"></div>
              <div className="orderDes skeleton height-20 w-40"></div>
            </div>
          </div>
          <div className="col-md-2 col-4 order-2">
            <div className="placedBy">
              <div className="orderTitle m5-bottom skeleton height-20 w-60"></div>
              <div className="orderDes skeleton height-20 w-40"></div>
            </div>
          </div>
          <div className="col-md-2 col-4 order-md-3 order-0 m0-md-bottom m10-bottom">
            <div className="totalPrice">
              <div className="orderTitle m5-bottom skeleton height-20 w-60"></div>
              <div className="orderDes skeleton height-20 w-40"></div>
            </div>
          </div>
          <div className="col-md-4 col-8 order-md-4 order-1 m0-md-bottom m10-bottom">
            <div className="orderId text-end">
              <div className="orderTitle m5-bottom skeleton height-20 w-60"></div>
              <div className="orderDes skeleton height-20 w-40"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="productOrderDetails row align-items-center justify-content-between p30-y p15-x">
        <div className="col-lg-10 col-md-9 col-12 showCart d-flex align-items-center">
          <div className="cartImage m20-right width-lg-95 width-75 height-lg-135 height-md-115 height-100 skeleton"></div>
          <div className="cartDetails w-50">
            <div className="prductTitle skeleton height-20 w-100 m0-bottom"></div>
            <div className="skeleton height-20 w-30  m5-bottom m5-top"></div>
            <div className="productPrice m4-top fs-xl-16 fs-12">
              <span className="skeleton height-20 w-40 me-2"></span>
            </div>
            <div className="skeleton height-20 w-30 m10-bottom"></div>
            <div className="skeleton height-20 w-10"></div>
          </div>
        </div>
        <div className=" col-lg-2 col-md-3 col-12 cartButtons">
          <div className="ordersButton row justify-content-between">
            <div className="col-md-12 col-6 m10-top">
              <button className="skeleton height-25 w-100 border-0 "></button>
            </div>
            <div className="d-md-none d-unset col-6 m10-top">
              <button className="skeleton height-25 w-100 border-0 "></button>
            </div>
            <div className="col-12">
              <button className="skeleton height-25 w-100 border-0"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
